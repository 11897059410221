<template>
    <div class="News">
        <div class="title-card">
            <img src="@/assets/xxzx.png" class="img" alt="" />
            <div class="text">消息中心</div>
        </div>

        <div class="bottom" v-infinite-scroll="load">
            <NoData v-if="!newsList.length" text="暂时没有收到消息"></NoData>
            <div
                class="news-card"
                v-for="(item, index) in newsList"
                :key="'news' + index"
            >
                <div class="flex-jsb top">
                    <div class="time">{{ item.created }}</div>
                    <div class="status" :class="{ already: item.read }">
                        {{ item.read ? "已读" : "未读" }}
                    </div>
                </div>
                <div class="ttile">{{ item.title }}</div>
                <div class="flex-ale between pl-20 pb-24 pr-20">
                    <div class="desc one-line">
                        {{ item.content }}
                    </div>
                    <div class="detail click" @click="toDetail(item)">
                        查看详情
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            :visible.sync="showNewsDetail"
            :modal-append-to-body="true"
            :show-close="true"
            class="news-dialog"
            width="570px"
        >
            <div class="title">{{ newsObj.title }}</div>
            <div class="time">{{ newsObj.created }}</div>
            <el-divider></el-divider>
            <div class="desc">
                {{ newsObj.content }}
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    export default {
        name: "News",
        components: {
            NoData,
        },
        data() {
            return {
                showNewsDetail: false, //信息详情弹框
                newsList: [], //信息列表
                has_next: false, //下一页
                newsObj: {}, //信息详情
                page: 1,
            };
        },

        mounted() {
            window.localStorage.setItem("myNavActive", 7);
            this.$store.commit("switchMyNav", 7);
            this.getData();
        },

        methods: {
            //下一页
            load() {
                if (!this.has_next) return console.log("到底了");
                this.page = this.page + 1;
                this.getData(this.page, 1);
            },
            //获取信息列表
            getData(page = 1, type = 0) {
                this.$https.get("/api/message/list", { page }).then((res) => {
                    if (res.errcode) return;
                    if (type) {
                        this.newsList = [...this.newsList, ...res.data.list];
                    } else {
                        this.newsList = res.data.list;
                    }
                    this.has_next = res.data.has_next;
                });
            },
            //获取信息详情
            toDetail(e) {
                if (e.status == 1) {
                    this.$https
                        .get("/api/message/detail", { id: e.id })
                        .then((res) => {
                            if (res.errcode) return this.$message.error(res.msg);
                        });
                    return this.$router.push({
                        path: "/questionnaire",
                        query: { id: e.questionnaire },
                    });
                }
                this.$https.get("/api/message/detail", { id: e.id }).then((res) => {
                    if (res.errcode) return this.$message.error(res.msg);
                    this.newsObj = res.data;
                    this.showNewsDetail = true;
                    this.$https.get("/api/message/list").then((res) => {
                        if (res.errcode) return;
                        this.$store.commit("newsNumber", res.data.unread || 0);
                    });
                    this.getData();
                    this.page = 1;
                });
            },
        },
    };
</script> 

<style lang="less" scoped>
.News {
    width: 100%;
    .title-card {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        padding: 22px 19px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .img {
            width: 34px;
            height: 28px;
        }
        .text {
            font-size: 18px;
            font-weight: 500;
            color: #2d2d2d;
            margin-left: 12px;
        }
    }
    .bottom::-webkit-scrollbar {
        opacity: 0;
        width: 0;
    }
    .bottom {
        width: 100%;
        background: #ffffff;
        border-radius: 10px;
        padding: 30px 23px;
        height: 670px;
        overflow: scroll;
        .news-card {
            border: 1px solid #f6f6f6;
            border-radius: 6px;
            width: 100%;
            margin-bottom: 20px;
            .top {
                padding: 10px 20px;
                box-shadow: 0px 1px 0px 0px #f6f6f6;
                .time {
                    font-size: 14px;
                    font-weight: 400;
                    color: #929292;
                }
                .status {
                    width: 57px;
                    height: 19px;
                    background: #fcdddd;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #f32929;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .already {
                    color: #21ad29;
                    background: #e6ffe7;
                }
            }
            .ttile {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                padding-left: 20px;
                padding-top: 10px;
            }
            .desc {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }
            .detail {
                min-width: 88px;
                margin-left: 15px;
                height: 33px;
                background: linear-gradient(180deg, #ff3764, #ff2604);
                border-radius: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .news-dialog {
        /deep/.el-dialog {
            border-radius: 10px;
            .el-dialog__header {
                padding: 0;
            }
            .el-dialog__body {
                padding: 24px 23px;
                display: flex;
                align-items: center;
                flex-direction: column;
                .title {
                    font-size: 21px;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 11px;
                }
                .time {
                    font-size: 14px;
                    font-weight: 400;
                    color: #929292;
                    margin-bottom: 20px;
                }
                .el-divider--horizontal {
                    margin: 0;
                }
                .desc {
                    margin-top: 4px;
                    padding: 24px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    width: 100%;
                }
            }
        }
    }
}
</style>